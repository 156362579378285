export default {
  name: 'investorInvoicesDetails',
  props: ['billHdrId'],
  data() {
    return {
      loader: false,
      invoiceDetailsData: {
        bill_hdr_id: null,
        total_amount: null,
        request_id: null,
        bill_no: null,
        request_num: null,
        template_id: null,
        template_name: null,
        vendor_id: null,
        vendor_name: null,
        vendor_site_id: null,
        vendor_site: null,
        vendor_site_address: null,
        gl_date: null,
        transactional_currency_id: null,
        currency: null,
        le_id: null,
        le_name: null,
        ou_id: null,
        ou_name: null,
        bill_status_vset: null,
        bill_status_vset_meaning: null,
        due_date: null,
        address: null,
        billing_period_start_date: null,
        billing_period_end_date: null,
        source_hdr_id: null,
        source_name: null,
        lease_prj_id: null,
        lease_prj_name: null,
        fms_sub_unit_id: null,
        fms_sub_unit_name: null,
        lease_schedule_type_vset: null,
        lease_schedule_type_vset_meaning: null,
        fms_floor_id: null,
        fms_floor_name: null,
        fms_tower_id: null,
        fms_tower_name: null,
        bill_date: null,
        trx_type: null,
        third_party_gstn: null,
        first_party_gstn: null,
        bill_line_details: []
      },
      scheduleType: {
        value: null,
        text: null
      },
      selectedBillTemplate: {
        value: null,
        text: null
      },
      selectedBillRequest: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedCurrency: {
        value: null,
        text: null
      },
      selectedSource: {
        value: null,
        text: null
      },
      invoiceBillList: [],
      invoiceBillFields: [
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'description'
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'value_date'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'tax_cat_name'
        },
        {
          key: 'tax_amount'
        }
      ]
    };
  },
  mounted() {
    if (this.billHdrId) {
      this.getInvestorInvoiceDetails(this.billHdrId);
    }
  },
  methods: {
    getInvestorInvoiceDetails(billHdrId) {
      this.loader = true;
      this.$store
        .dispatch('leaseInvoice/getInvestorInvoiceDetails', billHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.invoiceDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
