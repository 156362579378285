import appStrings from '@/app/utility/string.utility.js';
import DatePicker from 'vue2-datepicker';
import CodeCombination from '@/app/components/shared/codeCombination';
export default {
  name: 'viewInvoiceEntry',
  props: ['apInvoiceId', 'apInvoiceStatus'],
  components: {
    DatePicker,
    CodeCombination
  },
  data() {
    return {
      compositeCheck: false,
      billHdrId: null,
      totalRows: null,
      loader: false,
      distributionDetails: {},
      distributionList: [],
      distributionFeilds: [
        {
          key: 'invoice_line_num'
        },
        {
          key: 'distribution_number'
        },
        {
          key: 'concatenated_segment'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'amount'
        },
        {
          key: 'base_amount'
        },
        {
          key: 'posted_flag'
        },
        {
          key: 'cancel_flag'
        }
      ],
      accountingList: [],
      accountingFeilds: [
        {
          key: 'account_class',
          label: 'Charge Type'
        },
        {
          key: 'ccid',
          label: 'Code Combination'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'period_name'
        },
        {
          key: 'posting_flag',
          label: 'Posting'
        },
        {
          key: 'transaction_dr',
          label: 'Trx Amt DR',
          class: 'text-right'
        },
        {
          key: 'transaction_cr',
          label: 'Trx Amt CR',
          class: 'text-right'
        },
        {
          key: 'base_dr',
          class: 'text-right'
        },
        {
          key: 'base_cr',
          class: 'text-right'
        },
        {
          key: 'narration',
          class: 'text-right'
        }
      ],
      transactionDetails: {
        trx_number: null,
        trx_type: null,
        gl_date: null,
        trx_date: null,
        currency_id: null,
        currency: null,
        exchange_rate: null,
        source_hdr_id: null,
        source_name: null,
        customer: null,
        customer_id: null,
        customer_site: null,
        customer_site_id: null,
        bill_to_address_id: null,
        bill_to_address: null,
        party_id: null,
        address_id: null,
        address: null,
        trx_status_code: null,
        trx_status_meaning: null,
        narration: null,
        period_start_date: null,
        period_end_date: null,
        exchange_rate_date: null,
        unit_criteria: null,
        unit_name: null,
        debtor_account: null,
        transaction_line: []
      },
      invoiceLineList: [],
      invoiceLineFields: [
        {
          key: 'doc_num'
        },
        {
          key: 'line_num'
        },
        {
          key: 'parent_line_num'
        },
        {
          key: 'line_type'
        },
        {
          key: 'component_group',
          label: 'Component Group'
        },
        {
          key: 'component'
        },
        {
          key: 'invoice_amount',
          label: 'Line Amount',
          class: 'text-center'
        },
        {
          key: 'narration'
        },
        {
          key: 'sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'unit_name'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'prj_name'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'base_amount'
        },
        {
          key: 'tds_tax_cat_name',
          label: 'TDS Tax Category'
        },
        {
          key: 'tds_section_name',
          label: 'TDS Section'
        },
        {
          key: 'tds_rate',
          label: 'TDS Rate'
        },
        {
          key: 'ltdc_flag',
          label: 'LTDC'
        },
        {
          key: 'tds_override_flag',
          label: 'TDS Overide Flag'
        },
        {
          key: 'tds_tax_cat_actual_name',
          label: 'TDS Tax Category Actual'
        },
        {
          key: 'tds_section_actual_name',
          label: 'TDS Section Actual'
        },
        {
          key: 'tds_rate_actual',
          label: 'TDS Rate Actual'
        }
      ],
      invoiceDetails: {
        amount_paid: null,
        tds_deducted: null,
        ap_invoice_id: null,
        approval_status: null,
        approval_status_meaning: null,
        base_amount: null,
        cancel_reason: null,
        cancelled_by: null,
        cancelled_by_name: null,
        cancelled_date: null,
        creation_by: null,
        creation_date: null,
        currency: null,
        description: null,
        document_num: null,
        entity_id: null,
        entity_name: null,
        entity_type: null,
        entity_type_vset: null,
        erp_ou_id: null,
        exchange_date: null,
        exchange_rate: null,
        exchange_rate_type: null,
        external_bank_account_id: null,
        fp_gst_number: null,
        gl_date: null,
        internal_contact_email: null,
        batch_number: null,
        invoice_amount: null,
        invoice_date: null,
        invoice_line_total: null,
        invoice_num: null,
        invoice_received_date: null,
        invoice_recording_date: null,
        invoice_status_meaning: null,
        invoice_status_vset: null,
        invoice_type: null,
        invoice_type_dtl_id: null,
        last_updated_by: null,
        last_updated_date: null,
        le_id: null,
        le_name: null,
        module_id: null,
        module_name: null,
        org_ou_id: null,
        ou_name: null,
        pay_group_vset: null,
        pay_group_vset_meaning: null,
        payment_method: null,
        payment_method_vset: null,
        payment_status: null,
        payment_status_flag: null,
        payment_term: null,
        payment_terms_id: null,
        po_matched_flag: null,
        posting_status_meaning: null,
        postion_status: null,
        prepay_flag: null,
        relationship_id: null,
        remit_to_party_id: null,
        remit_to_party_name: null,
        remit_to_party_site: null,
        remit_to_party_site_id: null,
        request_num: null,
        set_of_books_id: null,
        set_of_books_name: null,
        source: null,
        supplier_tax_invoice: null,
        supplier_tax_invoice_date: null,
        supplier_tax_invoice_rate: null,
        tax_amount: null,
        terms_date: null,
        tp_gst_number: null,
        value_date: null,
        liability_ccid: null,
        liability_ccid_dlt: null,
        party_contact_id: null,
        party_id: null,
        party_name: null,
        party_site: null,
        party_site_id: null,
        party_site_address: null,
        einvoice_flag: false
      },
      scheduleList: [],
      scheduleFields: [
        {
          key: 'payment_nuum',
          label: 'Payment Num'
        },
        {
          key: 'due_date'
        },
        {
          key: 'schedule_line_type_vset',
          label: 'Schedule Line Type'
        },
        {
          key: 'gross_amount'
        },
        {
          key: 'amount_remaining'
        },
        {
          key: 'payment_priority'
        },
        {
          key: 'hold_date'
        },
        {
          key: 'payment_mode_vset_meaning',
          label: 'Payment Mode'
        },
        {
          key: 'payment_status_flag_meaning',
          label: 'Payment Status'
        },
        {
          key: 'hold_flag'
        },
        {
          key: 'held_by'
        },
        {
          key: 'hold_reason_vset_meaning',
          label: 'Hold Reason'
        },
        {
          key: 'hold_date'
        },
        {
          key: 'released_by'
        },
        {
          key: 'released_reason_vset_meaning',
          label: 'Released Reason'
        },
        {
          key: 'release_date'
        },
        {
          key: 'bank_account_number'
        }
      ],
      isViewMore: false,
      notificationSummary: false,
      notificationComments: null,
      approvalHistoryList: [],
      approvalHistoryFields: [
        {
          key: 'approver_name',
          label: 'Name'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments',
          label: 'Comments'
        },
        {
          key: 'action_date'
        }
      ],
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      urlSummaryData: null,
      wfDtlId: null,
      invoiceLineIndex: null,
      showValueSetModal: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      paymentList: [],
      paymentsFields: [
        {
          key: 'payment_ref_no'
        },
        {
          key: 'document_num'
        },
        {
          key: 'payment_method_name'
        },
        {
          key: 'payment_date'
        },
        {
          key: 'void'
        },
        {
          key: 'void_gl_date',
          label: 'Void GL Date'
        },
        {
          key: 'amount'
        }
      ],
      deleteBill: false,
      cancelDate: new Date(),
      cancelReason: null,
      paymentInvoiceList: [],
      paymentInvoiceFields: [
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'schedule_number'
        },
        {
          key: 'schedule_line_type_meaning',
          label: 'Schedule Line Type'
        },
        {
          key: 'schedule_amount'
        },
        {
          key: 'original_amount',
          label: 'Outstanding Amount'
        },
        {
          key: 'payment_amount',
          label: 'Paid in this Payment'
        }
      ],
      irnGenerated: null,
      parent_value_set_id: null,
      codeCombinationId:null,
      invoiceDistributionId:null,
      showCodeCombinationModal:false,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      dataIndex:null
    };
  },
  mounted() {
    if (this.apInvoiceId) {
      this.getApInvoiceDetail(this.apInvoiceId);
      this.getApDistribution(this.apInvoiceId);
      this.getApSchedule(this.apInvoiceId);
      this.getInvoiceLine(this.apInvoiceId);
      this.getEinvoiceDetailsById();
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    if (this.urlSummaryData) {
      this.getApInvoiceDetail(this.urlSummaryData.ap_invoice_id);
      this.getApDistribution(this.urlSummaryData.ap_invoice_id);
      this.getApSchedule(this.urlSummaryData.ap_invoice_id);
      this.getInvoiceLine(this.urlSummaryData.ap_invoice_id);
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
    }
    if (
      this.$route.name === 'notificationSummary' ||
      this.$route.name === 'dashboard' ||
      this.$route.name === 'workFlowSummary'
    ) {
      this.notificationSummary = true;
      this.getApprovalHistory(this.invoiceDetails.ap_invoice_id);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.updateInvoiceFields();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.apInvoiceId });
        }
        if (actionName === 'approval') {
          this.$bvModal.show('action-list');
        }
        if (actionName === 'delete') {
          this.$bvModal.show('cancel-modal');
        }
      }
    });
  },
  methods: {
    getApInvoiceDetail(ap_invoice_id) {
      this.loader = true;
      this.$store
        .dispatch('payable/getApInvoiceDetail', ap_invoice_id)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {            
            this.invoiceDetails = resp.data.data;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getApDistribution(ap_invoice_id) {
      this.$store
        .dispatch('payable/getApDistribution', ap_invoice_id)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
             this.distributionList = resp.data.data;
        }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getApSchedule(ap_invoice_id) {
      this.$store
        .dispatch('payable/getApSchedule', ap_invoice_id)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.scheduleList = resp.data.data;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getInvoiceLine(ap_invoice_id) {
      this.$store
        .dispatch('payable/getInvoiceLine', ap_invoice_id)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.invoiceLineList = resp.data.data;
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getApprovalHistory(ap_invoice_id) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: ap_invoice_id,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.invoiceDetails.ap_invoice_id);
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg.push(response.data.message);
          } else {
            this.isSuccess = false;
            this.responseMsg.push(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditInvoiceLineData() {
      const invLineDetails = this.invoiceLineList.map(elem => {
        return {
          tds_override_flag: elem.tds_override_flag,
          tds_rate_actual: parseFloat(elem.tds_rate),
          tds_section_actual: elem.tds_section_actual,
          tds_tax_cat_actual: elem.tds_tax_cat_actual,
          line_id: elem.line_id
        };
      });
      const payload = {
        requestParams: {
          ap_update_invoice_line: invLineDetails ? invLineDetails : null
        },
        invoiceId: this.apInvoiceId
      };
      this.loader = true;
      this.$store
        .dispatch('payable/addEditInvoiceLineData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditScheduleLineData() {
      const schLineDetails = this.scheduleList.map(elem => {
        return {
          invoice_schedule_id: elem.invoice_schedule_id,
          hold_flag: elem.hold_flag,
          hold_reason_vset: elem.hold_reason_vset,
          payment_priority: elem.payment_priority,
          released_reason_vset: elem.released_reason_vset,
          payment_mode_vset: elem.payment_mode_vset,
          due_date: elem.due_date,
          bank_account_id: elem.external_bank_account_id
        };
      });
      const payload = {
        requestParams: {
          ap_invoice_schedule_update: schLineDetails ? schLineDetails : null
        },
        invoiceId: this.apInvoiceId
      };
      this.loader = true;
      this.$store
        .dispatch('payable/addEditScheduleLineData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.invoiceLineIndex = index;
      this.showValueSetModal = true; 
      this.parent_value_set_id = null;
     if (this.vsetCode === appStrings.VALUESETTYPE.BANK_ACC_SCHEDULE) {
        this.parent_value_set_id = this.invoiceDetails.party_site_id
    }
      this.setTimeout = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TDS_TAX_CATEGORY_ACTUAL) {
        this.invoiceLineList[this.invoiceLineIndex].tds_tax_cat_actual_name =
          item.value_code;
        this.invoiceLineList[this.invoiceLineIndex].tds_tax_cat_actual =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_SECTION_ACTUAL) {
        this.invoiceLineList[this.invoiceLineIndex].tds_section_actual_name =
          item.value_code;
        this.invoiceLineList[this.invoiceLineIndex].tds_section_actual =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.TDS_RATE_ACTUAL) {
        this.invoiceLineList[this.invoiceLineIndex].tds_rate_actual =
          item.tax_name;
        this.invoiceLineList[this.invoiceLineIndex].tds_rate = item.tax_rate;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.HOLD_REASON) {
        this.scheduleList[this.invoiceLineIndex].hold_reason_vset =
          item.value_code;
        this.scheduleList[this.invoiceLineIndex].hold_reason_vset_meaning =
          item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RELEASE_REASON_VSET) {
        this.scheduleList[this.invoiceLineIndex].released_reason_vset =
          item.value_code;
        this.scheduleList[this.invoiceLineIndex].released_reason_vset_meaning =
          item.value_meaning;
      }  else if (this.vsetCode === appStrings.VALUESETTYPE.BANK_ACC_SCHEDULE) {
        this.scheduleList[this.invoiceLineIndex].external_bank_account_id =
          item.party_bank_id;
        this.scheduleList[this.invoiceLineIndex].bank_account_number =
          item.bank;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PAYMENT_MODE) {
        this.scheduleList[this.invoiceLineIndex].payment_mode_vset =
          item.value_code;
        this.scheduleList[this.invoiceLineIndex].payment_mode_vset_meaning =
          item.value_meaning;
      }
      else {
        this.partyType = item.value_code;
        this.party = {
          name: null,
          id: null
        };
        this.partySite = {
          name: null,
          id: null
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    updateInvoiceFields() {
      const payload = {
        invoiceHdrId: this.apInvoiceId,
        updatedFields: {
          ap_invoice_update: {
            supplier_tax_exchange_rate: this.invoiceDetails
              .supplier_tax_invoice_rate,
            supplier_tax_invoice: this.invoiceDetails.supplier_tax_invoice,
            supplier_tax_invoice_date: this.invoiceDetails
              .supplier_tax_invoice_date,
            tax_invoice_recording_date: this.invoiceDetails
              .invoice_recording_date,
            batch_no: this.invoiceDetails.batch_number,
            einvoice_flag: this.invoiceDetails.einvoice_flag
          }
        }
      };
      this.loader = true;
      this.$store
        .dispatch('payable/updateInvoiceEntry', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.getApInvoiceDetail(this.apInvoiceId);
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    getAccountingDistribution() {
      this.loader = true;
      this.$store
        .dispatch('payable/getInvoiceAccountingList', this.apInvoiceId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.accountingList = response.data.data;
          }
        })
        .catch(err => {
          alert(err.message);
          this.loader = false;
        });
    },
    validateInvoice() {
      this.loader = true;
      const payload = {
        invoiceId: this.apInvoiceId,
        requestBody: {
          le_id: this.invoiceDetails.le_id,
          ou_id: this.invoiceDetails.org_ou_id,
          invoice_id: this.invoiceDetails.ap_invoice_id,
          trx_type_dtl_id: this.invoiceDetails.trx_type_dtl_id
        }
      };
      this.$store
        .dispatch('payable/validateInvoiceData', payload)
        .then(response => {
          this.loader = false;
          this.$bvModal.hide('action-list');
          if (response.status === 200) {
            this.getApInvoiceDetail(this.apInvoiceId);
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    getInvoicePayments() {
      this.loader = true;
      this.$store
        .dispatch('payable/getInvoicePaymentsList', this.apInvoiceId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentList = response.data.data;
          }
        })
        .catch(err => {
          alert(err.message);
          this.loader = false;
        });
    },
    cancelInvoice() {
      this.loader = true;
      const payload = {
        invoiceId: this.apInvoiceId,
        requestBody: {
          delete_bill: this.deleteBill,
          cancel_date: this.cancelDate,
          cancel_reason: this.cancelReason
        }
      };
      this.$store
        .dispatch('payable/cancelInvoiceData', payload)
        .then(response => {
          this.loader = false;
          this.$bvModal.hide('cancel-modal');
          if (response.status === 200) {
            if (this.invoiceDetails.invoice_status_vset !== 'Never Validated') {
              this.getApInvoiceDetail(this.apInvoiceId);
            }
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    showPaymentInvoices(item) {
      this.$bvModal.show('invoice-modal');
      this.getPaymentInvoices(item);
    },
    getPaymentInvoices(params) {
      // To get invoice data
      this.loader = true;
      this.paymentInvoiceList = [];
      this.$store
        .dispatch('manualPayments/getManualPaymentById', params.payment_hdr_id)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.paymentInvoiceList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEinvoiceDetailsById() {
      const payload = {
        module_id: this.$store.state.shared.moduleId,
        trx_id: this.apInvoiceId
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getEinvoiceDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.irnGenerated = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearCancelFields() {
      this.cancelReason = null;
      this.cancelDate = null;
      this.deleteBill = false;
    },
    downloadExcelData() {
      this.loader = true;
      const downloadpayload = this.apInvoiceId;
      this.downloadExcel.downloadDetails(
        downloadpayload,
        'payable/getApDistribution',
        'invoice-distribution',
        () => (this.loader = false)
      );
    },
    downloadExcelDetails() {
      this.loader = true;
      const downloadpayload = this.apInvoiceId;
      this.downloadExcel.downloadDetails(
        downloadpayload,
        'payable/getInvoiceAccountingList',
        'accounting',
        () => (this.loader = false)
        );
    },
    updateInvoiceDistribution(){
      const distributionListpayload = this.distributionList.map(elem => {
        return {
          invoice_distribution_id: elem.invoice_distribution_id,
          dist_code_combination_id: elem.dist_code_combination_id,
        };
      });
      const payload = {
        invoice_distributions:distributionListpayload
      };      
      this.loader = true;
      this.$store
        .dispatch('payable/updateInvoiceDistributions', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getApDistribution(this.apInvoiceId);
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideCodeCombinationModal(flag,item) {
      this.showCodeCombinationModal = flag;  
      if(flag){
        this.combinationDetails= {
          le_id: this.invoiceDetails.le_id,
          project_code: this.invoiceDetails.project_code
        }
        this.dataIndex=item        
      }
      
    },
    selectedSegment(item){      
      this.distributionList[this.dataIndex].concatenated_segment=item.segment_code;
      this.distributionList[this.dataIndex].concatenated_segment_meaning=item.segment_meaning;
      this.distributionList[this.dataIndex].dist_code_combination_id= item.ccid;
      
      this.showHideCodeCombinationModal(false);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  destroyed() {
    this.eventBus.$off('getTransactionDetails');
  }
};
